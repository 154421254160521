/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: Schedule.js
File Description: schedule playlist functionality

Copyright 2021 - 2023 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../utils/config';
import { useHistory } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import { T, useT } from '@transifex/react';
import { ToastContainer, toast } from "react-toastify";
import { t } from "@transifex/native";
import LoadingOverlay from "react-loading-overlay";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import subDays from "date-fns/subDays";
import { registerLocale } from "react-datepicker";
import { et } from 'date-fns/locale';
import $ from "jquery";
import { Buffer } from 'buffer';

function Schedule(props) {

  const tabTitle = useT("Schedule");
  var schedulePlaylistData = JSON.parse(localStorage.getItem('schedulePlaylist'));
  var userdetails = JSON.parse(localStorage.getItem('userdetails'));
  const [selectedType, setSelectedType] = useState('forever');
  const [scheduled, setScheduled] = useState(false);
  const [days, setDays] = useState([]);
  const [inputs, setInputs] = useState({});
  const history = useHistory();
  const [modalShow, setmodalShow] = useState(false);
  const modalHandleClose = () => setmodalShow(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [playlistPriority, setPlaylistPriority] = useState('1');

  //const encodedString = Buffer.from(config.auth_username + ':' + config.auth_password).toString('base64');
  //const basicAuth = 'Basic ' + encodedString;

  // Browser Tab Title
  useEffect(() => {
    document.title = tabTitle + ' | CMS';
  }, [])

  const selectTypeClicked = (type) => {
    setStartDate(null);
    setEndDate(null);
    setStartTime(null);
    setEndTime(null);
    setPlaylistPriority('1');
    setSelectedType(type);
    setDays([]);
    schedulePlaylistData.ScheduleType = type;
    localStorage.setItem('schedulePlaylist', JSON.stringify(schedulePlaylistData));
    return null;
  }

  const handleCheckBox = (event) => {
    const existingDays = [...days];
    if (existingDays.includes(event.target.id)) {
      var index = existingDays.indexOf(event.target.id);
      existingDays.splice(index, 1);
    } else {
      existingDays.push(event.target.id);
    }
    setDays(existingDays)
  }

  const foreverproceed = () => {
    schedulePlaylistData.ForeverCheck = 'proceed';
    localStorage.setItem('schedulePlaylist', JSON.stringify(schedulePlaylistData));
    setmodalShow(false);
    scheduleplaylist();
  }

  const forevercancel = () => {
    history.push('/');
  }

  const formatDate = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  const formatTime = (time) => {
    var d = new Date(time);
    var hours = d.getHours();
    var mins = d.getMinutes();
    return [hours, mins].join(':');
  }

  const scheduleplaylist = (event) => {
    var sDate = '';
    var eDate = '';
    var sTime = '';
    var eTime = '';

    let error = false;
    let dateerror = false;
    let timeerror = false;
    if (schedulePlaylistData.ScheduleType === "once") {
      if (startDate) {
        sDate = formatDate(startDate);
      } else {
        toast.error(t('!name field is required.').replace('!name', t('Start Date')));
        error = true;
        dateerror = true;
      }
      if (startTime) {
        sTime = formatTime(startTime);
      } else {
        toast.error(t('!name field is required.').replace('!name', t('Start Time')));
        error = true;
        timeerror = true;
      }
      if (endDate) {
        eDate = formatDate(endDate);
      } else {
        toast.error(t('!name field is required.').replace('!name', t('End Date')));
        error = true;
        dateerror = true;
      }
      if (endTime) {
        eTime = formatTime(endTime);
      } else {
        toast.error(t('!name field is required.').replace('!name', t('End Time')));
        error = true;
        timeerror = true;
      }

      if ((dateerror === false) && (startDate && endDate)) {
        let startingdate = new Date(startDate).getTime();
        let endingdate = new Date(endDate).getTime();
        if (startingdate > endingdate) {
          toast.error(t('End date should be greater than Start date'));
          error = true;
        } else if ((timeerror === false) && startingdate === endingdate) {
          let startingtime = new Date(startTime).getTime();
          let endingtime = new Date(endTime).getTime();
          if (startingtime >= endingtime || endingtime-startingtime < 1000) {
            toast.error(t('End time should be greater than Start time'));
            error = true;
          }
        }
      }
    }
    if (schedulePlaylistData.ScheduleType === "recurring") {
      if (startDate) {
        sDate = formatDate(startDate);
      } else {
        toast.error(t('!name field is required.').replace('!name', t('Start Date')));
        error = true;
        dateerror = true;
      }
      if (startTime) {
        sTime = formatTime(startTime);
      } else {
        toast.error(t('!name field is required.').replace('!name', t('Start Time')));
        error = true;
        timeerror = true;
      }
      if (endDate) {
        eDate = formatDate(endDate);
      } else {
        toast.error(t('!name field is required.').replace('!name', t('End Date')));
        error = true;
        dateerror = true;
      }
      if (endTime) {
        eTime = formatTime(endTime);
      } else {
        toast.error(t('!name field is required.').replace('!name', t('End Time')));
        error = true;
        timeerror = true;
      }

      if ((dateerror === false) && (startDate && endDate)) {
        let startingdate = new Date(startDate).getTime();
        let endingdate = new Date(endDate).getTime();
        if (startingdate > endingdate) {
          toast.error(t('End date should be greater than Start date'));
          error = true;
        } else if (timeerror === false) {
          let startingtime = new Date(startTime).getTime();
          let endingtime = new Date(endTime).getTime();
          if (startingtime >= endingtime || endingtime-startingtime < 1000) {
            toast.error(t('End time should be greater than Start time'));
            error = true;
          }
        }
      }

      if (days === undefined || days.length === 0) {
        toast.error(t('Please select Days of Week within selected date range.'));
        error = true;
      } else {
        //if days diff is less than 7
        var from = getConvertedDate(sDate);
        var to = getConvertedDate(eDate);
        var daysdiff = datediff(from, to);
        if (daysdiff < 7) {
          var weekdays = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
          var validDays = [];
          for (var day = from; day <= to; day.setDate(day.getDate() + 1)) {
            validDays.push(weekdays[day.getDay()]);
          }
          validDays = (validDays.join(","));
          var weekdaysError = false;
          $.each(days, function (i, val) {
            if (validDays.includes(val) === false) {
              weekdaysError = true;
            }
          });
          if (weekdaysError === true) {
            toast.error(t("Please select Days of Week within selected date range."));
            return false;
          }
        }
      }
    }


    if (error === false) {
      setScheduled(true);
      schedulePlaylistData.ScheduleTypeData = inputs;
      schedulePlaylistData.Days = days;
      if (schedulePlaylistData.ScheduleType === "forever") {
        if (schedulePlaylistData.ForeverCheck !== 'proceed') {
          schedulePlaylistData.ForeverCheck = 'inital';
        }
      } else {
        schedulePlaylistData.ForeverCheck = '';
      }
      localStorage.setItem('schedulePlaylist', JSON.stringify(schedulePlaylistData));
      let url = config.drupal_url + '/api/rest/schedule_playlist?_format=hal_json';
      const data = {
        "PlaylistTitle": { "value": schedulePlaylistData.PlaylistTitle },
        "PlaylistType": { "value": schedulePlaylistData.PlaylistType },
        "PlaylistId": { "value": schedulePlaylistData.PlaylistId },
        "screenIds": { "value": schedulePlaylistData.screenIds },
        "ScheduleType": { "value": schedulePlaylistData.ScheduleType },
        "StartDate": { "value": sDate },
        "StartTime": { "value": sTime },
        "EndDate": { "value": eDate },
        "EndTime": { "value": eTime },
        "Days": { "value": schedulePlaylistData.Days },
        "UserData": { "value": userdetails },
        "ForeverCheck": { "value": schedulePlaylistData.ForeverCheck },
        "Priority": { "value": playlistPriority },
	    "delete_id": { "value": "" },
		"device_id": { "value": "" }
      }

      axios.get(config.drupal_url + '/session/token')
        .then(response => {
          const csrf_token = response.data;
          axios.post(url, data, {
            headers: {
              "Content-Type": "application/hal+json",
              withCredentials: true,
              "X-CSRF-Token": csrf_token,
            }
          }).then(response => {
            if (response.data === "forever already scheduled") {
              setScheduled(false);
              setmodalShow(true);
            } else if ((response.data).includes('Schedule Conflict')) {
              const conflictTitle = (response.data).split("---");
              let errorMsg = t('@device_unitid reached to its maximum allowed schedule limit @schedule_count. Please revisit scheduled playlists.').replace(/@device_unitid/gi, conflictTitle[1]).replace(/@schedule_count/gi, conflictTitle[2]);
              toast.error(errorMsg);
              setScheduled(false);
            } else {
              history.push('/');
              let errorMsg = t('Playlist _ scheduled successfully.').replace('_', schedulePlaylistData.PlaylistTitle);
              toast.success(errorMsg);
            }
          }).catch(error => {
            history.push('/');
            toast.error(t('Error scheduling. Please try later. If the error persists, please contact Otis support.'));
            setScheduled(false);
          });
        })
    }
  }

  let StartDate = t('Start Date');
  let StartTime = t('Start Time');
  let EndDate = t('End Date');
  let EndTime = t('End Time');
  let Priority = t('Priority');

  const transDays = [
    useT('Sun'),
    useT('Mon'),
    useT('Tue'),
    useT('Wed'),
    useT('Thu'),
    useT('Fri'),
    useT('Sat')
  ];

  const transMonths = [
    useT('January'),
    useT('February'),
    useT('March'),
    useT('April'),
    useT('May'),
    useT('June'),
    useT('July'),
    useT('August'),
    useT('September'),
    useT('October'),
    useT('November'),
    useT('December')
  ];

  const transTime = useT("Hour");

  const transDatePicker = {
    localize: {
      day: n => transDays[n],
      month: n => transMonths[n]
    },
    formatLong: {
      date: () => 'dd/mm/yyyy'
    }
  }

  registerLocale('bg', transDatePicker);

  const datediff = (first, second) => {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }
  const getConvertedDate = (date) => {
	var newdate = new Date(date);
    return new Date(newdate.getTime() + newdate.getTimezoneOffset() * 60000);
  }
  const handleChangeRaw = (e) => {
    e.preventDefault();
  }

  // The date format from app settings for datepicker
  var dateFormat = "dd/MM/yyyy";
  var userdata = localStorage.getItem("userdetails");
  var requiredFormat = userdata && JSON.parse(userdata).dateformat;
  if(requiredFormat) {
    var dateFormathalf = requiredFormat.replace('DD', 'dd');
    dateFormat = dateFormathalf.replace('YYYY', 'yyyy');
  }

  return (
    <div>
      <ToastContainer />
      <LoadingOverlay active={scheduled} spinner text={t('Processing')}>
        {/* Header Section */}
        <div className="scheduleheaderSection">
          <div className="headderSteps">
            <div className="headderTitle">
              <h1 className="pageTitle"><T _str="Schedule Playlist" /></h1>
              <div className="firstsubtitle"><span className="orangeColor firstWord">{schedulePlaylistData.PlaylistTitle}</span><T _str="Playlist will be added to" /><span className="orangeColor">{schedulePlaylistData.screenIds.length}</span><T _str="selected screens" /></div>
              <div className="subtitle">
                <T _str="Select schedule, and click" /> <T _str="Submit" /> <T _str="to publish the playlist" />
              </div>
            </div>
            <div className="allStepsWrapper">
              <div className="stepWrapper finished one">
                <a href="add-screens">
                  <span class="stepNumber">1</span>
                  <span class="stepTitle"><span class="orangeColor"><em class="fa fa-check"></em></span><T _str="Add Screens" /></span>
                </a>
              </div>
              <div className="stepsSeperator"></div>
              <div className="stepWrapper active two">
                <a href="#!">
                  <span class="stepNumber">2</span>
                  <span class="stepTitle"><T _str="Schedule Playlist" /></span>
                </a>
              </div>
            </div>
          </div>
          <div className="headerAction">
            <div className="ActionButton">
              <button
                className="btn btn-success form-submit"
                onClick={scheduleplaylist}>
                <T _str="Submit" />
              </button>
            </div>
          </div>
        </div>
        {/* Schedule Playlist Section */}
        <div className="schedulePlaylistSection">
          <div className="TypeButtons">
            <button
              id="forever"
              onClick={(event) => [selectTypeClicked(event.target.id)]}
              className={selectedType === 'forever' ? 'active' : ''}>
              <T _str="Forever" />
            </button>
            <button
              id="once"
              onClick={(event) => [selectTypeClicked(event.target.id)]}
              className={selectedType === 'once' ? 'active' : ''}>
              <T _str="Once" />
            </button>
            <button
              id="recurring"
              onClick={(event) => [selectTypeClicked(event.target.id)]}
              className={selectedType === 'recurring' ? 'active' : ''}>
              <T _str="Recurring" />
            </button>
          </div>
          {selectedType === 'once' && (
            <div className="scheduleTypeOnceFields">
              <div className="box first">
                <div className="dateTimeField">
                  <label title={StartDate}>{StartDate}</label>
                  <DatePicker
                    id="once-start-date"
                    dateFormat= {dateFormat}
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    minDate={subDays(new Date(), 1)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    autoComplete="off"
                    formatWeekDay={nameOfDay => nameOfDay.substr(0, 3)}
                    locale='bg'
                    onChangeRaw={(e)=>handleChangeRaw(e)}
                  />
                </div>
                <div className="dateTimeField">
                  <label title={StartTime}>{StartTime}</label>
                  <DatePicker
                    id="once-start-time"
                    selected={startTime}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={60}
                    timeCaption={transTime}
                    timeFormat="HH:mm"
                    dateFormat="HH:mm"
                    autoComplete="off"
                    onChange={(time) => setStartTime(time)}
                    onChangeRaw={(e)=>handleChangeRaw(e)}
                  />
                </div>
              </div>
              <div className="box">
                <div className="dateTimeField">
                  <label title={EndDate}>{EndDate}</label>
                  <DatePicker
                    id="once-end-date"
                    dateFormat={dateFormat}
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    minDate={subDays(new Date(), 1)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    autoComplete="off"
                    formatWeekDay={nameOfDay => nameOfDay.substr(0, 3)}
                    locale='bg'
                    onChangeRaw={(e)=>handleChangeRaw(e)}
                  />
                </div>
                <div className="dateTimeField">
                  <label title={EndTime}>{EndTime}</label>
                  <DatePicker
                    id="once-end-time"
                    selected={endTime}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={60}
                    timeCaption={transTime}
                    timeFormat="HH:mm"
                    dateFormat="HH:mm"
                    autoComplete="off"
                    onChange={(time) => setEndTime(time)}
                    onChangeRaw={(e)=>handleChangeRaw(e)}
                  />
                </div>
              </div>
              <div className="priorityField">
                  <label title={Priority}>{Priority}</label>
                  <select id="priority"
                    onChange={(e) => setPlaylistPriority(e.target.value)}>
                   <option value="1" selected="selected">{t("Normal")}</option>
                    <option value="2">{t("Medium")}</option>
                    <option value="3">{t("High")}</option>
                  </select>
                  <p>{t("Selecting high priority will increase the number of times your playlist will be played")}</p>
              </div>
            </div>
          )}
          {selectedType === 'recurring' && (
            <div className="scheduleTypeRecurFields">
              <div className="scheduleTypeSectionTitle"><T _str="Custom Recurrence" /></div>
              <div className="dateTimeField">
                <label title={StartDate}>{StartDate}</label>
                <DatePicker
                  id="rec-start-date"
                  dateFormat={dateFormat}
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  minDate={subDays(new Date(), 1)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  autoComplete="off"
                  formatWeekDay={nameOfDay => nameOfDay.substr(0, 3)}
                  locale='bg'
                  onChangeRaw={(e)=>handleChangeRaw(e)}
                />
              </div>
              <div className="dateTimeField">
                <label title={StartTime}>{StartTime}</label>
                <DatePicker
                  id="rec-start-time"
                  selected={startTime}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={60}
                  timeCaption={transTime}
                  timeFormat="HH:mm"
                  dateFormat="HH:mm"
                  autoComplete="off"
                  onChange={(time) => setStartTime(time)}
                  onChangeRaw={(e)=>handleChangeRaw(e)}
                />
              </div>
              <div className="weekDays-selector">
                <label className="mainLabel"><T _str="Days of Week" /></label>
                <input type="checkbox" id="sun" name="day" className="weekday"
                  checked={days.includes('sun') ? 1 : 0}
                  onChange={handleCheckBox} />
                <label><T _str="Sun" /></label>
                <input type="checkbox" id="mon" name="day"
                  checked={days.includes('mon') ? 1 : 0}
                  className="weekday"
                  onChange={handleCheckBox} />
                <label><T _str="Mon" /></label>
                <input type="checkbox" id="tue" name="day" className="weekday"
                  checked={days.includes('tue') ? 1 : 0}
                  onChange={handleCheckBox} />
                <label><T _str="Tue" /></label>
                <input type="checkbox" id="wed" name="day" className="weekday"
                  checked={days.includes('wed') ? 1 : 0}
                  onChange={handleCheckBox} />
                <label><T _str="Wed" /></label>
                <input type="checkbox" id="thu" name="day" className="weekday"
                  checked={days.includes('thu') ? 1 : 0}
                  onChange={handleCheckBox} />
                <label><T _str="Thu" /></label>
                <input type="checkbox" id="fri" name="day" className="weekday"
                  checked={days.includes('fri') ? 1 : 0}
                  onChange={handleCheckBox} />
                <label><T _str="Fri" /></label>
                <input type="checkbox" id="sat" name="day" className="weekday"
                  checked={days.includes('sat') ? 1 : 0}
                  onChange={handleCheckBox} />
                <label><T _str="Sat" /></label>
              </div>
              <div className="dateTimeField">
                <label title={EndDate}>{EndDate}</label>
                <DatePicker
                  id="rec-end-date"
                  dateFormat={dateFormat}
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  minDate={subDays(new Date(), 1)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  autoComplete="off"
                  formatWeekDay={nameOfDay => nameOfDay.substr(0, 3)}
                  locale='bg'
                  onChangeRaw={(e)=>handleChangeRaw(e)}
                />
              </div>
              <div className="dateTimeField">
                <label title={EndTime}>{EndTime}</label>
                <DatePicker
                  id="rec-end-time"
                  selected={endTime}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={60}
                  timeCaption={transTime}
                  timeFormat="HH:mm"
                  dateFormat="HH:mm"
                  autoComplete="off"
                  onChange={(time) => setEndTime(time)}
                  onChangeRaw={(e)=>handleChangeRaw(e)}
                />
              </div>
              <div className="priorityField">
                  <label title={Priority}>{Priority}</label>
                  <select id="priority"
                    onChange={(e) => setPlaylistPriority(e.target.value)}>
                    <option value="1" selected="selected">{t("Normal")}</option>
                    <option value="2">{t("Medium")}</option>
                    <option value="3">{t("High")}</option>
                  </select>
                  <p>{t("Selecting high priority will increase the number of times your playlist will be played")}</p>
              </div>
            </div>
          )}
        </div>
      </LoadingOverlay>
      <Modal className="forevervalidationmodal" show={modalShow} onHide={modalHandleClose}>
        <Modal.Body>
          <div className="foreverValidationText">
            {t("The selected playlist is already scheduled forever on this device.")} <br /> {t("Do you still want to continue?")}
            <div className="modal-actions">
              <button className='btn' onClick={foreverproceed} >{t("Yes")}</button>
              <button className='btn' onClick={forevercancel} >{t("No")}</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div >
  );
}

export default Schedule;
