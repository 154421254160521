/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: CalendarPage.js
File Description: Implements schedule from calendar functionality of CMS portal

Copyright 2021 - 2023 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment, { weekdays } from 'moment';
import moment_timezone from 'moment-timezone';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useState, useEffect } from 'react';
import config from '../utils/config';
import axios from "axios";
import "../css/calendar.css";
import { T, useT } from '@transifex/react';
import { t } from '@transifex/native';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { confirmAlert } from 'react-confirm-alert';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import $ from "jquery";
import { FaTimesCircle } from 'react-icons/fa';
import { useHistory } from "react-router-dom";
import CustomToolbar from '../Components/Calendar/CustomToolbar';
import LoadingOverlay from "react-loading-overlay";
import { RRule, RRuleSet, rrulestr } from 'rrule';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import subDays from "date-fns/subDays";
import { registerLocale } from "react-datepicker";
import { et } from 'date-fns/locale';
import { NodeWithChildren } from 'domhandler';
import { Buffer } from 'buffer';
import { appInsights, severity } from "../AppInsights"; 

function CalendarPage(props) {
  const tabTitle = t("Calendar");
  useEffect(() => {

    document.title = tabTitle + ' | CMS';

  }, [tabTitle]);
  var userdetails = localStorage.getItem('userdetails');
  const userid = userdetails && JSON.parse(userdetails).id;
  var parenId ="";
  if(localStorage.getItem("parentuser")){
       var parentuser = localStorage.getItem("parentuser");
       parenId = parentuser && JSON.parse(parentuser).id;
   }else{
       parenId = userdetails && JSON.parse(userdetails).id;
   }
   let appinsights_flag = localStorage.getItem("appinsights");
  //const encodedString = Buffer.from(config.auth_username + ':' + config.auth_password).toString('base64');
  //const basicAuth = 'Basic ' + encodedString;
  const tloadmore = t('Load more')
 
   const localizer = momentLocalizer(moment);
  const formats = {
    weekdayFormat: (date, culture, localizer) => t(localizer.format(date, 'ddd', culture)),
  }

  const getRRval = (wday) => {
    if (wday.trim() != '') {
      switch (wday.trim()) {
        case 'Sun': return RRule.SU;
        case 'Mon': return RRule.MO;
        case 'Tue': return RRule.TU;
        case 'Wed': return RRule.WE;
        case 'Thu': return RRule.TH;
        case 'Fri': return RRule.FR;
        case 'Sat': return RRule.SA;
      }
    }
  }

  const getRRules = (staDate, endDate, weekDays) => {
    let weekArr = weekDays.split(',')
    let byweekdayArr = []
    weekArr.map((wday) => {
      byweekdayArr.push(getRRval(wday))
    });

    let simpdate = endDate.split('T')
    let endDateAr = simpdate[0].split('-')
	let endTimeAr = simpdate[1].split(':')
    let simpstdate = staDate.split('T')
    let stDateAr = simpstdate[0].split('-')
	let stTimeAr = simpstdate[1].split(':')
    // Create a rule:
    return new RRule({
      freq: RRule.WEEKLY,
      interval: 1,
      byweekday: byweekdayArr,
      dtstart: new Date(stDateAr[0], stDateAr[1]-1, stDateAr[2], stTimeAr[0], stTimeAr[1]),
      until: new Date(endDateAr[0], endDateAr[1]-1, endDateAr[2], endTimeAr[0], endTimeAr[1])
    })
  }


  const now = new Date();
  let fetchUrl = config.drupal_url + '/calendar-schedules?_format=json&field_assign_user_target_id=' + userid
  //let dateArray =[]
  let eventsArray = []
  const [events, setEvents] = useState([]);
  const history = useHistory();

  //dashboard screens page calendar?screen_id=1118
  const queryParams = new URLSearchParams(window.location.search);
  const screen_id = queryParams.get('screen_id');
  const [modalShow, setmodalShow] = useState(false);
  const [modalShow1, setmodalShow1] = useState(false);
  const [modalShow2, setmodalShow2] = useState(false);
  const modalHandleClose1 = () => setmodalShow(false);
  const modalHandleClose2 = () => setmodalShow(false);
  const modalHandleShow = () => setmodalShow(true);
  const [selectedType, setSelectedType] = useState('forever');
  const [days, setDays] = useState([]);
  const [daysValid, setDaysValid] = useState([]);
  const [inputs, setInputs] = useState({});
  const [isloading, setLoading] = useState(true);
  const [isloading1, setLoading1] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [playlistPriority, setPlaylistPriority] = useState('1');

  const formatDate = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  const formatTime = (time) => {
    var d = new Date(time);
    var hours = d.getHours();
    var mins = d.getMinutes();
    return [hours, mins].join(':');
  }

  
  const convertTZ = (date, tzString) => {
    var indianTime = moment(new Date(date)).tz(tzString).format();
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
  }
 
  const selectTypeClicked = (type, title, id) => {

    if (type === "remove") {

      setmodalShow2(true);
    }
    setSelectedType(type);
    return null;

  }
  const modalHandleClose = () => {
    let inputsdata = {
      "StartDate": formatDate(startDate),
      "StartTime": formatTime(startTime),
      "EndDate": formatDate(endDate),
      "EndTime": formatDate(endTime)
    };
    setInputs(inputsdata);
    setmodalShow(false);
  }
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name in inputs) {
      const PreviousInputs = inputs;
      PreviousInputs[name] = value;
      setInputs(PreviousInputs);
    } else {
      setInputs(values => ({ ...values, [name]: value }))
    }
  }
  const handleCheckBox = (event) => {

    const existingDays = [...days];
    if (existingDays.includes(event.target.id)) {
      var index = existingDays.indexOf(event.target.id);
      existingDays.splice(index, 1);
    } else {
      existingDays.push(event.target.id);
    }
    const existingDaysValid = [...daysValid];
    if (existingDaysValid.includes(event.target.id)) {
      var index = existingDaysValid.indexOf(event.target.id);
      existingDaysValid.splice(index, 1);
    } else {
      existingDaysValid.push(event.target.id);
    }
    setDaysValid(existingDaysValid);
    setDays(existingDays);
  }
  const [scheduledata, setScheduleData] = useState([]);

  const eventStyleGetter = (event, start, end, isSelected) => {
    let date1 = start.toISOString().split('T')[0]
    let date2 = now.toISOString().split('T')[0]
    let ActDataArr = event.activate_schedule
    let backgroundColor='';
    let recFlag = 1;


    if(event.type == 'recurring' &&  (date1 < date2 || date1 > date2)){
			      recFlag = 0;
    }
	if(event.type == 'recurring' && recFlag == 1 &&
	(moment(event.enddatetime).format('HH:mm:ss') < moment(now).format('HH:mm:ss') ||
	moment(event.startdatetime).format('HH:mm:ss') > moment(now).format('HH:mm:ss'))) {
	recFlag = 0;
	}
    if(recFlag == 0 && event.type === 'forever'){
      recFlag = 1;
      event.activate_schedule[event.screenid]['playlist_'+event.id] = event.id
    }
    if(ActDataArr){
      backgroundColor = (event.enabled=='1' && recFlag ==1 && event.activate_schedule[event.screenid]['playlist_'+event.id] == event.id)?'#166916':'';
    }
    let style = { backgroundColor: backgroundColor }
    return {
      style: style
    };
  }

  if (screen_id) {
    fetchUrl = fetchUrl + '&field_screen_id_target_id=' + screen_id
  }

  useEffect(() => {
    setLoading(true);

    axios.get(config.drupal_url + '/session/token').then(result => {
      if (200 === result.status) {
        const csrfToken = result.data;
        const  headers= {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken,
          }

        axios.get(fetchUrl, {headers})
          .then(resData => {

            let res = resData.data
            let activateSch = {}
            let sortedData = {}
            let tempEventsArray = []

            res.map((node) => {
            let dateArray = node.field_schedule_date.split(' - ');
			      let frmdate = dateArray[0].substring(0, dateArray[0].length - 1).split('+');
			      let tdate = dateArray[1].substring(0, dateArray[1].length - 1).split('+');
            let fromdate = frmdate[0];
            let todate =  tdate[0];
         

			     if(node.field_recurrence === "On"){
            const rRuleStr = getRRules(fromdate, todate, node.field_weekdays)
            let rrarr = rRuleStr.all()
            fromdate = rrarr[0]
            }

            tempEventsArray.push({'id':node.nid,
            'type': (node.field_recurrence === "On") ? 2 : (node.field_forever === "No") ? 3 :  1,
            'screenid': node.field_screen_id_1,
            'startdatetime': new Date(moment(fromdate)),
            'enddatetime': new Date(moment(todate)),
            'priority':node.field_priority
            })
           // sortedData = tempEventsArray.slice().sort((a, b) => b.type - a.type);
           sortedData = tempEventsArray;
          })

	        if(sortedData.length > 0){
          sortedData.map((node) => {
            const startDate = moment(now);
            const timeEnd = moment(node.enddatetime);
            const timeStart = moment(node.startdatetime);
            const diff = timeEnd.diff(startDate);
              if(node.screenid in activateSch==false){
                activateSch[node.screenid] = {}
              }
              //3 is Once, 2 is Recurrence and 1 is Forever
              if(node.type==3 && diff > 0 && timeStart.diff(startDate) <0 ){
                activateSch[node.screenid]['playlist_'+node.id] = node.id;
              }else if(node.type==2 && timeStart.diff(startDate) <0 && diff > 0){
                activateSch[node.screenid]['playlist_'+node.id] = node.id;
              }else if(node.type==1 && timeStart.diff(startDate) <0 && diff > 0){
                activateSch[node.screenid]['playlist_'+node.id] = node.id;
              }
            })
		      }
            res.map((node) => {
              let dateArray = node.field_schedule_date.split(' - ');
              let frmdate = dateArray[0].substring(0, dateArray[0].length - 1).split('+');
              let fromdate = frmdate[0];
              let tdate = dateArray[1].substring(0, dateArray[1].length - 1).split('+');
              let todate = tdate[0];

              let field_weekdays = '';
              if (node.field_weekdays === '') {
                field_weekdays = 'Sun,Mon,Tue,Wed,Thu,Fri,Sat';
              } else {
                field_weekdays = node.field_weekdays;
              }
              const rRuleStr = getRRules(fromdate, todate, field_weekdays)
              let rrarr = rRuleStr.all()

              rrarr.map((datetime) => {
                eventsArray.push({
                  'id': node.nid,
                  'title': t('Playlist') + ': ' + node.title + ' ' + t('Screen') + ': ' + node.field_screen_id_2,
                  'start': new Date(datetime),
                  'end': new Date(datetime),
                  'device_name': node.field_devicename,
                  'type': (node.field_recurrence === "On") ? "recurring" : (node.field_forever === "No") ? "once" : "forever",
                  'weekdays': node.field_weekdays && JSON.stringify(node.field_weekdays).toLowerCase(),
                  'playlist_id': node.field_playlist_id,
                  'screenid': node.field_screen_id_1,
                  'enabled': node.field_flag,
                  'playlist_name': node.title,
                  'allDay': 'false',
                  'startdatetime': new Date(fromdate),
                  'enddatetime': new Date(todate),
                  'activate_schedule': activateSch,
                  'priority':node.field_priority
                })
              });

            })
            setEvents(eventsArray)
            setLoading(false);
          })


      }
    });

  }, []);



  const foreverproceed = () => {
    scheduledata.ForeverCheck = 'proceed';
    setmodalShow1(false);
    setmodalShow(true);
    schedule_submit();
  }

  const forevercancel = () => {
    setmodalShow1(false);
    setmodalShow(true);
  }

  const handleSelect = (e) => {
    const scheduledata = {
      "id": e.id,
      "title": e.title,
      "start": e.startdatetime.getTime(),
      "end": e.enddatetime.getTime(),
      "device": e.device_name,
      'type': e.type,
      'weekdays': e.weekdays,
      'playlist_id': e.playlist_id,
      'screenid': e.screenid,
      'playlist_name': e.playlist_name,
      'priority':e.priority
    }
    const IndiaStartTime = e.startdatetime;
    const IndiaStartTimeStamp = IndiaStartTime.getTime();
    const IndiaEndTime = e.enddatetime;
    const IndiaEndTimeStamp = IndiaEndTime.getTime();
    setScheduleData(scheduledata);
    setStartDate(IndiaStartTime);
    setStartTime(IndiaStartTimeStamp);
    setEndDate(IndiaEndTime);
    setEndTime(IndiaEndTimeStamp);
    setPlaylistPriority(e.priority);
    setSelectedType(e.type);
    let weekdaysFormat = e.weekdays && (e.weekdays).replace(/\s/g, '').replace(/"/g, '');
    setDays(e.weekdays);
    if (weekdaysFormat) {
      setDaysValid(weekdaysFormat.split(","));
    }
    setmodalShow(true)
  }

  const deleteproceed = () => {
    setLoading1(true);
    setmodalShow2(false);
    const url = config.drupal_url + "/api/rest/schedule_playlist?_format=hal_json";
	  var userdetails = localStorage.getItem('userdetails');
    var scheduledata_id = scheduledata.id;
    const data = {
      "delete_id": { "value": scheduledata.id },
	  "device_id": { "value": scheduledata.screenid },
      "UserData": { "value": userdetails && JSON.parse(userdetails) }
    }
    axios.get(config.drupal_url + '/session/token')
    .then(response => {
      const csrf_token = response.data;
      axios.post(url, data, {
        headers: {
          "Content-Type": "application/hal+json",
          withCredentials: true,
          "X-CSRF-Token": csrf_token,
        }
      }).then(response => {

        var str = t("@type %title was successfully deleted.");
        var mapObj = { "@type": t("Schedule Playlist"), "%title": scheduledata.playlist_name };
        str = str.replace(/@type|%title/gi, function (matched) {
          return mapObj[matched];
        });
        if (response.data === "Success") {
          toast.success(str)
          //if (appinsights_flag == 1) {
            appInsights.trackTrace({
              message:
                "The schedule playlist '" +
                scheduledata.playlist_name +
                "' with nid:" +
                scheduledata_id +
                " has been successfully deleted by user with uid:" +
                parenId,
                severityLevel: severity.Information,
            });	
          //}
        } else {
          toast.error(t('Please try later.If the error persists, please contact Otis support.'));
        }
        setTimeout(() => {
          window.location.reload()
        }, 5000);

      }).catch(error => {
        toast.error(t('Please try later.If the error persists, please contact Otis support.'));
        //if (appinsights_flag == 1) {
           appInsights.trackException({ error , severityLevel: severity.Information});
        //}
        setLoading1(false);
    
      });
    })    
  }

  const deletecancel = () => {
    setmodalShow2(false);
  }

  const datediff = (first, second) => {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }
  const getConvertedDate = (date) => {
	var newdate = new Date(date);
    return new Date(newdate.getTime() + newdate.getTimezoneOffset() * 60000);
  }
  const customId = userdetails && JSON.parse(userdetails).id;
  const notify = (val) => {
    toast.error(val, {
      toastId: customId
    });
  }
  const schedule_submit = (event) => {
    var sDate = '';
    var eDate = '';
    var sTime = '';
    var eTime = '';
    let error = false;
    let dateerror = false;
    let timeerror = false;
    if (selectedType === "once") {
      if (startDate) {
        sDate = formatDate(startDate);
      } else {
        var msg = t('!name field is required.').replace('!name', t('Start Date'));
        notify(msg);
        error = true;
        dateerror = true;
      }
      if (startTime) {
        sTime = formatTime(startTime);
      } else {
        var msg = t('!name field is required.').replace('!name', t('Start Time'));
        notify(msg);
        error = true;
        timeerror = true;
      }
      if (endDate) {
        eDate = formatDate(endDate);
      } else {
        var msg = t('!name field is required.').replace('!name', t('End Date'));
        notify(msg);
        error = true;
        dateerror = true;
      }
      if (endTime) {
        eTime = formatTime(endTime);
      } else {
        var msg = t('!name field is required.').replace('!name', t('End Time'));
        notify(msg);
        error = true;
        timeerror = true;
      }

      if ((dateerror === false) && (startDate && endDate)) {
        let startingdate = new Date(startDate.toDateString()).getTime();
        let endingdate = new Date(endDate.toDateString()).getTime();

        if (startingdate > endingdate) {
          var msg = t('End date should be greater than Start date');
          notify(msg);
          error = true;
        } else if ((timeerror === false) && startingdate === endingdate) {

          let todayDate = new Date();
          let startDatetime = Date.parse(formatDate(todayDate) + " " + formatTime(startTime))/1000;
          let endDatetime = Date.parse(formatDate(todayDate) + " " + formatTime(endTime)) / 1000;
          if (startDatetime >= endDatetime || endDatetime-startDatetime < 1000) {
            var msg = t('End time should be greater than Start time');
            notify(msg);
            error = true;
          }
        }
      }
    }

    if (selectedType === "recurring") {
      if (startDate) {
        sDate = formatDate(startDate);
      } else {
        var msg = t('!name field is required.').replace('!name', t('Start Date'));
        notify(msg);
        error = true;
        dateerror = true;
      }
      if (startTime) {
        sTime = formatTime(startTime);
      } else {
        var msg = t('!name field is required.').replace('!name', t('Start Time'));
        notify(msg);
        error = true;
        timeerror = true;
      }
      if (endDate) {
        eDate = formatDate(endDate);
      } else {
        var msg = t('!name field is required.').replace('!name', t('End Date'));
        notify(msg);
        error = true;
        dateerror = true;
      }
      if (endTime) {
        eTime = formatTime(endTime);
      } else {
        var msg = t('!name field is required.').replace('!name', t('End Time'));
        notify(msg);
        error = true;
        timeerror = true;
      }

      if ((dateerror === false) && (startDate && endDate)) {
        let startingdate = new Date(startDate.toDateString()).getTime();
        let endingdate = new Date(endDate.toDateString()).getTime();

        if (startingdate > endingdate) {
          var msg = t('End date should be greater than Start date');
          notify(msg);
          error = true;
        } else if (timeerror === false) {

          let todayDate = new Date();
          let startDatetime = Date.parse(formatDate(todayDate) + " " + formatTime(startTime))/1000;
          let endDatetime = Date.parse(formatDate(todayDate) + " " + formatTime(endTime)) / 1000;
          if (startDatetime >= endDatetime || endDatetime-startDatetime < 1000) {
            var msg = t('End time should be greater than Start time');
            notify(msg);
            error = true;
          }
        }
      }

      if (daysValid === undefined || daysValid.length === 0) {
        var msg = t('Please select Days of Week within selected date range.');
        notify(msg);
        error = true;
      } else {
        //if days diff is less than 7
        var daysdiff = (datediff(getConvertedDate(startDate), getConvertedDate(endDate)));
        var selectedWeekdays = new Array();
        var validateWeekdays = new Array();

        daysValid.forEach(function (currentValue) {
          selectedWeekdays.push(currentValue);
          validateWeekdays.push(currentValue);
        });

        if (daysdiff < 7) {
          var from = getConvertedDate(sDate);
          var to = getConvertedDate(eDate);

          var weekdays = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
          var selectedDateDays = new Array();

          for (var day = from; day <= to; day.setDate(day.getDate() + 1)) {

            selectedDateDays.push(weekdays[day.getDay()]);
          }
          selectedDateDays = (selectedDateDays.join(","));

          var errorWeekdays = '';

          $.each(selectedWeekdays, function (i, val) {

            if (selectedDateDays.includes(val) === false) {
              errorWeekdays += val + ' ';
            }
          });

          if (errorWeekdays !== "") {
            var msg = t("Please select Days of Week within selected date range.");
            notify(msg);
            return false;
          }
        }
      }
    }



    if (error === false) {
      setLoading1(true);

      if (selectedType === "forever") {
        if (scheduledata.ForeverCheck !== 'proceed') {
          scheduledata.ForeverCheck = 'inital';
        }
      } else {
        scheduledata.ForeverCheck = '';
      }
      let url = config.drupal_url + '/api/rest/schedule_playlist?_format=hal_json';


      const data = {
        "ScheduleId": { "value": scheduledata.id },
        "PlaylistTitle": { "value": scheduledata.playlist_name },
        "PlaylistType": { "value": selectedType },
        "PlaylistId": { "value": scheduledata.playlist_id },
        "screenIds": { "value": [scheduledata.screenid] },
        "ScheduleType": { "value": selectedType },
        "StartDate": { "value": sDate },
        "StartTime": { "value": sTime },
        "EndDate": { "value": eDate },
        "EndTime": { "value": eTime },
        "Days": { "value": daysValid },
        "UserData": { "value": userdetails && JSON.parse(userdetails) },
        "ForeverCheck": { "value": scheduledata.ForeverCheck },
        "Update": 1,
        "Priority": { "value": playlistPriority },
		"delete_id": { "value": "" },
		"device_id": { "value": "" }
      }

      axios.get(config.drupal_url + '/session/token')
        .then(response => {
          const csrf_token = response.data
          axios.post(url, data, {
            headers: {
              "Content-Type": "application/hal+json",
              withCredentials: true,
              "X-CSRF-Token": csrf_token,
            }
          }).then(response => {
            if (response.data === "forever already scheduled") {
              setmodalShow1(true);
              setmodalShow(false);
              setLoading1(false);
            } else if ((response.data).includes('Schedule Conflict')) {
              const conflictTitle = (response.data).split("---");
              let errorMsg = t('@device_unitid reached to its maximum allowed schedule limit @schedule_count. Please revisit scheduled playlists.').replace(/@device_unitid/gi, conflictTitle[1]).replace(/@schedule_count/gi, conflictTitle[2]);
			  toast.error(errorMsg);
              setLoading1(false);
            } else {
              setTimeout(() => {
                window.location.reload(false);
              }, 5000);
              var str = t("@type %title has been updated.");
              var mapObj = { "@type": t("Schedule Playlist"), "%title": scheduledata.playlist_name };
              str = str.replace(/@type|%title/gi, function (matched) {
                return mapObj[matched];
              });
              toast.success(str);
              //if (appinsights_flag == 1) {
                appInsights.trackTrace({
                  message:
                    "The schedule playlist '" +
                    scheduledata.playlist_name +
                    "' with nid:" +
                    scheduledata.id +
                    " has been successfully updated by user with uid:" +
                    parenId,
                    severityLevel: severity.Information,
                });	
              //}
            }
          }).catch(error => {
            toast.error(t('Please try later.If the error persists, please contact Otis support.'));
            //if (appinsights_flag == 1) {
               appInsights.trackException({ error , severityLevel: severity.Information});
            //}
            setLoading1(false);
          });
        })
    }
  }

  const transDays = [
    useT('Sun'),
    useT('Mon'),
    useT('Tue'),
    useT('Wed'),
    useT('Thu'),
    useT('Fri'),
    useT('Sat')
  ];

  const transMonths = [
    useT('January'),
    useT('February'),
    useT('March'),
    useT('April'),
    useT('May'),
    useT('June'),
    useT('July'),
    useT('August'),
    useT('September'),
    useT('October'),
    useT('November'),
    useT('December')
  ];

  const transTime = useT("Hour");

  const transDatePicker = {
    localize: {
      day: n => transDays[n],
      month: n => transMonths[n]
    },
    formatLong: {
      date: () => 'dd/mm/yyyy'
    }
  }

  registerLocale('bg', transDatePicker);

  const handleChangeRaw = (e) => {
    e.preventDefault();
  }

  // The date format from app settings for datepicker
  var dateFormat = "dd/MM/yyyy";
  var userdata = localStorage.getItem("userdetails");
  var requiredFormat = userdata && JSON.parse(userdata).dateformat;
  if(requiredFormat) {
    var dateFormathalf = requiredFormat.replace('DD', 'dd');
    dateFormat = dateFormathalf.replace('YYYY', 'yyyy');
  }

  return (
    <LoadingOverlay active={isloading} spinner text={t('Processing')}>
      <div>
        <div className="headerSection">
          <h1 className="pageTitle">
            eView<sup>&reg;</sup> - <T _str="Calendar" />
          </h1>
          <div className="subtitle calendartitle-txt">
            <T _str="Default playlist will appear on any device if no playlist has been scheduled." />
          </div>
        </div>
        <Modal show={modalShow} className="topModal calendar-popup">
          <LoadingOverlay active={isloading1} spinner text={t('Processing')}>
            <div className="playlistModal" id="playlistModal">
              <Modal.Header>
                <ToastContainer />
                <Modal.Title><h4 class="modal-title">{t("Schedule")} {scheduledata.playlist_name} {t("for")} {scheduledata.device}</h4></Modal.Title>
                <button type="button" class="btn-close" aria-label="Close" onClick={modalHandleClose}></button>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <div className="schedulePlaylistSection">
                    <div className="TypeButtons">
                      <button
                        id="forever"
                        onClick={(event) => [selectTypeClicked(event.target.id)]}
                        className={selectedType === 'forever' ? 'active' : ''}>
                        {t("Forever")}
                      </button>
                      <button
                        id="once"
                        onClick={(event) => [selectTypeClicked(event.target.id)]}
                        className={selectedType === 'once' ? 'active' : ''}>
                        {t("Once")}
                      </button>
                      <button
                        id="recurring"
                        onClick={(event) => [selectTypeClicked(event.target.id)]}
                        className={selectedType === 'recurring' ? 'active' : ''}>
                        {t("Recurring")}
                      </button>
                      <button
                        id="remove"
                        onClick={(event) => [selectTypeClicked(event.target.id, scheduledata.playlist_name, scheduledata.id)]}
                        className={selectedType === 'remove' ? 'active' : ''}>
                        {t("Remove")}
                      </button>
                    </div>
                    {selectedType === 'once' && (
                      <div className="scheduleTypeOnceFields">
                        <div className="scheduleTypeSectionTitle">{t("Schedule")}</div>
                        <div className="box first">
                          <div className="dateTimeField">
                            <label>{t("Start Date")}</label>
                            <DatePicker
                              id="once-start-date"
                              dateFormat={dateFormat}
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              autoComplete="off"
                              minDate={subDays(new Date(), 1)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              formatWeekDay={nameOfDay => nameOfDay.substr(0, 3)}
                              locale='bg'
                              onChangeRaw={(e) => handleChangeRaw(e)}
                            />
                          </div>
                          <div className="dateTimeField">
                            <label>{t("Start Time")}</label>
                            <DatePicker
                              id="once-start-time"
                              selected={startTime}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={60}
                              timeCaption={transTime}
                              timeFormat="HH:00"
                              dateFormat="HH:00"
                              onChange={(time) => setStartTime(time)}
                              autoComplete="off"
                              onChangeRaw={(e) => handleChangeRaw(e)}
                            />                         </div>
                        </div>
                        <div className="box">
                          <div className="dateTimeField">
                            <label>{t("End Date")}</label>
                            <DatePicker
                              id="once-end-date"
                              dateFormat={dateFormat}
                              selected={endDate}
                              onChange={(date) => setEndDate(date)}
                              autoComplete="off"
                              minDate={subDays(new Date(), 1)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              formatWeekDay={nameOfDay => nameOfDay.substr(0, 3)}
                              locale='bg'
                              onChangeRaw={(e) => handleChangeRaw(e)}
                            />                         </div>
                          <div className="dateTimeField">
                            <label>{t("End Time")}</label>
                            <DatePicker
                              id="once-end-time"
                              selected={endTime}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={60}
                              timeCaption={transTime}
                              timeFormat="HH:00"
                              dateFormat="HH:00"
                              onChange={(time) => setEndTime(time)}
                              autoComplete="off"
                              onChangeRaw={(e) => handleChangeRaw(e)}
                            />                          </div>
                        </div>

                        <div className="calPriorityField">
                          <label title={t("Priority")}>{t("Priority")}</label>
                          <select id="priority"
                            value={playlistPriority}
                            onChange={(e) => setPlaylistPriority(e.target.value)}>
                            <option value="1" selected="selected">{t("Normal")}</option>
                            <option value="2">{t("Medium")}</option>
                            <option value="3">{t("High")}</option>
                          </select>
                          <p>{t("Selecting high priority will increase the number of times your playlist will be played")}</p>
                      </div>
                      </div>
                    )}
                    {selectedType === 'recurring' && (
                      <div className="scheduleTypeRecurFields">
                        <div className="scheduleTypeSectionTitle">{t("Custom Recurrence")}</div>
                        <div className="dateTimeField">
                          <label>{t("Start Date")}</label>
                          <DatePicker
                            id="rec-start-date"
                            dateFormat={dateFormat}
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            autoComplete="off"
                            minDate={subDays(new Date(), 1)}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            formatWeekDay={nameOfDay => nameOfDay.substr(0, 3)}
                            locale='bg'
                            onChangeRaw={(e) => handleChangeRaw(e)}
                          />
                        </div>
                        <div className="dateTimeField">
                          <label>{t("Start Time")}</label>
                          <DatePicker
                            id="rec-start-time"
                            selected={startTime}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={60}
                            timeCaption={transTime}
                            timeFormat="HH:00"
                            dateFormat="HH:00"
                            onChange={(time) => setStartTime(time)}
                            autoComplete="off"
                            onChangeRaw={(e) => handleChangeRaw(e)}
                          />
                        </div>
                        <div className="weekDays-selector">
                          <label className="mainLabel">{t("Days of Week")}</label>
                          <input type="checkbox" id="sun" name="day" className="weekday"
                            checked={daysValid.includes('sun') ? 1 : 0}
                            onChange={handleCheckBox} />
                          <label>{t("Sun")}</label>
                          <input type="checkbox" id="mon" name="day"
                            checked={daysValid.includes('mon') ? 1 : 0}
                            className="weekday"
                            onChange={handleCheckBox} />
                          <label>{t("Mon")}</label>
                          <input type="checkbox" id="tue" name="day" className="weekday"
                            checked={daysValid.includes('tue') ? 1 : 0}
                            onChange={handleCheckBox} />
                          <label>{t("Tue")}</label>
                          <input type="checkbox" id="wed" name="day" className="weekday"
                            checked={daysValid.includes('wed') ? 1 : 0}
                            onChange={handleCheckBox} />
                          <label>{t("Wed")}</label>
                          <input type="checkbox" id="thu" name="day" className="weekday"
                            checked={daysValid.includes('thu') ? 1 : 0}
                            onChange={handleCheckBox} />
                          <label>{t("Thu")}</label>
                          <input type="checkbox" id="fri" name="day" className="weekday"
                            checked={daysValid.includes('fri') ? 1 : 0}
                            onChange={handleCheckBox} />
                          <label>{t("Fri")}</label>
                          <input type="checkbox" id="sat" name="day" className="weekday"
                            checked={daysValid.includes('sat') ? 1 : 0}
                            onChange={handleCheckBox} />
                          <label>{t("Sat")}</label>
                        </div>
                        <div className="dateTimeField">
                          <label>{t("End Date")}</label>
                          <DatePicker
                            id="rec-end-date"
                            dateFormat={dateFormat}
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            autoComplete="off"
                            minDate={subDays(new Date(), 1)}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            formatWeekDay={nameOfDay => nameOfDay.substr(0, 3)}
                            locale='bg'
                            onChangeRaw={(e) => handleChangeRaw(e)}
                          />
                        </div>
                        <div className="dateTimeField">
                          <label>{t("End Time")}</label>
                          <DatePicker
                            id="rec-end-time"
                            selected={endTime}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={60}
                            timeCaption={transTime}
                            timeFormat="HH:00"
                            dateFormat="HH:00"
                            onChange={(time) => setEndTime(time)}
                            autoComplete="off"
                            onChangeRaw={(e) => handleChangeRaw(e)}
                          />
                        </div>
                        <div className="calPriorityField">
                          <label title={t("Priority")}>{t("Priority")}</label>
                          <select id="priority"
                            value={playlistPriority}
                            onChange={(e) => setPlaylistPriority(e.target.value)}>
                            <option value="1" selected="selected">{t("Normal")}</option>
                            <option value="2">{t("Medium")}</option>
                            <option value="3">{t("High")}</option>
                          </select>
                          <p>{t("Selecting high priority will increase the number of times your playlist will be played")}</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Modal.Body>
              {selectedType !== 'remove' &&
                <Modal.Footer>
                  <Button variant="primary" onClick={schedule_submit} >
                    <T _str="Save" />
                  </Button>
                </Modal.Footer>
              }
            </div>
          </LoadingOverlay>
        </Modal>
        <div className="row col-md-12">
          <div className="calendardiv">
            <Calendar
              toolbar={true}
              selectable={true}
              events={events}
              startAccessor="start"
              endAccessor="end"
              defaultDate={moment().toDate()}
              localizer={localizer}
              views={['month', 'day']}
              defaultView='month'
              onSelectEvent={handleSelect}
              popup={true}
              eventPropGetter={eventStyleGetter}
              formats={formats}
              components={{
                toolbar: CustomToolbar
              }}
              messages={{
                showMore: total => (
                  <span>{`+${total} `+t('more')}</span>
                ),
              }}
            />
          </div>
        </div>
        <Modal show={modalShow2} onHide={modalHandleClose2} className="delete-schedules">
          <Modal.Body>
            <div className="deleteConfirmbox">
              {t('Are you sure you want to remove this scheduled playlist ?')}
              <div className='deleteConfirmbox-actions'>
                <button className='btn' onClick={deleteproceed} >{t("Yes")}</button>
                <button className='btn' onClick={deletecancel} >{t("No")}</button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <div className="forever-check-overlay">
        <Modal show={modalShow1} className="forever-check">
          <Modal.Body>
            <div className="foreverValidationText">
              {t("The selected playlist is already scheduled forever on this device.")} <br /> {t("Do you still want to continue?")}
              <div className="modal-actions">
                <button className='btn' onClick={foreverproceed} >{t("Yes")}</button>
                <button className='btn' onClick={forevercancel} >{t("No")}</button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </LoadingOverlay>
  );
}

export default CalendarPage;
